import React, { useEffect, useMemo } from 'react';
import { ApplicationTitle } from '@getvim/atomic-ui';
import { ScopedLogger } from '@getvim/vim-connect-logger';
import { getLogger } from './logger';
import { AppStateProvider } from '../stores/AppState.store';
import Tabs from './tabs/Tabs';
import RegularUser from './regular-user/RegularUser';
import './App.less';
import { sessionIdManager } from '../utils/sessionIdManager';
import { useCdeConfig } from '../hooks/useCdeConfig';
import { useSdkAccessToken, useSdkActions, useSdkAnalytics } from './useSdkApp';
import { useFeatureFlag } from '@getvim/feature-flags-react';
import { Team } from '@getvim/feature-flags';
import { DATA_EXCHANGE_ADMIN_ROLE } from '../consts';

export const appLogger = getLogger({ scope: 'CDE App' });

export const App = () => {
  const accessToken = useSdkAccessToken();
  const analyticsReady = useSdkAnalytics();
  const runActionReady = useSdkActions();

  const { user, deviceId, organization, ehrVendor, bareboneType, bareboneVersion } = useCdeConfig();

  const [shouldConsiderCdeUserRole, isLoadingFF] = useFeatureFlag({
    flagName: 'shouldConsiderCdeUserRole',
    defaultValue: false,
    team: Team.CDE,
    flagContext: {
      organizationId: organization?.identifiers?.id,
    },
  });

  const isAppReady = useMemo(
    () => !!accessToken && analyticsReady && runActionReady && !isLoadingFF,
    [accessToken, analyticsReady, runActionReady, isLoadingFF],
  );
  const isCdeAdmin = useMemo(() => {
    appLogger.info('Checking if user is CDE Admin', { userRoles: user?.identifiers?.roles });
    return (
      !shouldConsiderCdeUserRole || user?.identifiers?.roles?.includes(DATA_EXCHANGE_ADMIN_ROLE)
    );
  }, [user?.identifiers?.roles, shouldConsiderCdeUserRole]);

  useEffect(() => {
    ScopedLogger.updateMetadata({
      organization_name: organization?.identifiers?.name,
      organizationId: organization?.identifiers?.id,
      adapterName: `${ehrVendor} ${bareboneType} ${bareboneVersion}`,
      deviceId: deviceId,
    });
  }, [organization, ehrVendor, deviceId, bareboneType, bareboneVersion]);

  useEffect(() => {
    if (!deviceId || !user?.identifiers?.vimUserID) return;

    sessionIdManager.handleShouldUpdate({
      userExternalId: user?.identifiers?.vimUserID,
      deviceId: deviceId,
    });
  }, [deviceId, user?.identifiers]);

  const userRoles = useMemo(() => user?.identifiers?.roles || [], [user?.identifiers?.roles]);

  return isAppReady ? (
    <>
      <div className="app-title">
        <ApplicationTitle title="Clinical Data Exchange" />
      </div>
      <AppStateProvider>
        <div className="app-content">
          {isCdeAdmin ? (
            <Tabs userRoles={userRoles} shouldConsiderCdeUserRole={shouldConsiderCdeUserRole} />
          ) : (
            <RegularUser userRoles={userRoles} />
          )}
        </div>
      </AppStateProvider>
    </>
  ) : null;
};
